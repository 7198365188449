<template>
  <v-pagination
    v-model="currentPage"
    :length="lastPage"
    :total-visible="5"
  ></v-pagination>
</template>

<script>
import { SET_CURRENT_PAGE } from "@/store/mutations.type";

export default {
  name: "Pagination",
  props: ["store", "collection", "searchStr"],
  watch: {
    currentPage: function(newVal, oldVal) {
      if (!(newVal == 1 && oldVal == 1)) {
        this.paginatePage(newVal);
      }
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state[this.store][this.collection].current_page;
      },
      set(value) {
        return this.$store.commit(`${this.store}/${SET_CURRENT_PAGE}`, value, {
          root: true,
        });
      },
    },
    lastPage: {
      get() {
        return this.$store.state[this.store][this.collection].last_page;
      },
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    paginatePage(pageNumber) {
      this.$store.dispatch(
        `${this.store}/fetch${this.capitalizeFirstLetter(this.collection)}List`,
        { pageNumber: pageNumber, searchStr: this.searchStr }
      );
    },
  },
};
</script>

<style scoped></style>
