<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          placeholder="Search"
          filled
          dense
          prepend-inner-icon="mdi-magnify"
          v-model="searchStr"
          @keyup.enter.native="fetchUsers"
          class="float-right"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="users"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      sort-by="calories"
      class="elevation-1"
      hide-default-footer
      :items-per-page="25"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- Create New User Dialog Box            -->
          <v-dialog v-model="dialogUser" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }} User Account</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="user.name"
                        label="Name"
                        @change="$v.user.name.$touch()"
                        :error-messages="checkNameErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="user.email"
                        label="Email"
                        type="email"
                        @change="$v.user.email.$touch()"
                        :error-messages="checkEmailErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="user.password"
                        label="Password"
                        type="password"
                        @change="$v.user.password.$touch()"
                        :error-messages="checkPasswordErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="user.confirmPassword"
                        label="Confirm Password"
                        type="password"
                        @change="$v.user.confirmPassword.$touch()"
                        :error-messages="checkConfirmPasswordErrors"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Delete New User Dialog Box            -->
          <!-- <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this staff
                member?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteUserConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editUser(item)">
          mdi-pencil
        </v-icon>
        <!-- <v-icon small @click="deleteUser(item)">
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
    <br /><br />
    <Pagination
      store="users"
      collection="users"
      :search-str="searchStr"
    ></Pagination>
  </v-container>
</template>

<script>
import {
  FETCH_USERS,
  REGISTER,
  DELETE_USER,
  UPDATE_USER,
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import { email, required, sameAs, minLength } from "vuelidate/lib/validators";
import Pagination from "@/views/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data: () => ({
    dialogUser: false,
    dialogDelete: false,
    deleteUserIndex: null,
    editUserIndex: null,
    searchStr: "",
    headers: [
      {
        text: "Id",
        align: "start",
        value: "id",
      },
      { text: "Active", value: "active", sortable: false },
      { text: "Name", value: "name", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    user: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    defaultUser: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  }),
  validations: {
    user: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    ...mapGetters("users", ["users", "isLoading"]),
    formTitle() {
      return this.editUserIndex === -1 ? "New" : "Edit";
    },
    checkNameErrors() {
      const nameErrors = [];
      if (!this.$v.user.name.$dirty) return nameErrors;
      !this.$v.user.name && nameErrors.push("Must be valid name");
      !this.$v.user.name.required && nameErrors.push("Name is required");
      return nameErrors;
    },
    checkEmailErrors() {
      const emailErrors = [];
      if (!this.$v.user.email.$dirty) return emailErrors;
      !this.$v.user.email.email && emailErrors.push("Must be valid e-mail");
      !this.$v.user.email.required && emailErrors.push("E-mail is required");
      return emailErrors;
    },
    checkPasswordErrors() {
      const passwordErrors = [];
      if (!this.$v.user.password.$dirty) return passwordErrors;
      !this.$v.user.password.required &&
        passwordErrors.push("Password is required");
      !this.$v.user.password.minLength &&
        passwordErrors.push("Password length should be greater than 8");
      return passwordErrors;
    },
    checkConfirmPasswordErrors() {
      const confirmPasswordErrors = [];
      if (!this.$v.user.confirmPassword.$dirty) return confirmPasswordErrors;
      !this.$v.user.confirmPassword.required &&
        confirmPasswordErrors.push("confirmPassword is required");
      !this.$v.user.confirmPassword.sameAsPassword &&
        confirmPasswordErrors.push("Must be same as password");
      return confirmPasswordErrors;
    },
  },
  watch: {
    dialogUser(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      this.loading = true;
      this.$store
        .dispatch(`users/${FETCH_USERS}`, {
          pageNumber: 1,
          searchStr: this.searchStr,
        })
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialogUser = false;
      this.$nextTick(() => {
        this.user = Object.assign({}, this.defaultUser);
        this.editUserIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteUser(user) {
      this.dialogDelete = true;
      this.deleteUserIndex = user.id;
    },
    deleteUserConfirm() {
      const userId = this.deleteUserIndex;
      this.$store.dispatch(DELETE_USER, { userId });
    },
    editUser(user) {
      this.dialogUser = true;
      this.editUserIndex = user.id;
      this.user = Object.assign({}, user);
    },
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.editUserIndex === -1) {
          this.$store.dispatch(REGISTER, {
            name: this.user.name,
            email: this.user.email,
            password: this.user.password,
          });
        } else {
          this.$store.dispatch(UPDATE_USER, {
            id: this.user.id,
            name: this.user.name,
            email: this.user.email,
            password: this.user.password,
          });
        }
      }
    },
  },
};
</script>
